import React from 'react';

const PriceScreen = () => {
	return (
		<section id="precios" className="container-price col-12 d-flex justify-content-center">
			<div className="content-price col-10 col-xl-8 d-flex justify-content-between flex-wrap">
				<div className="content-info col-12">
					<div className="title">
						<hr className="liner" />
						<h2>PRECIO</h2>
					</div>
					<div className="body">
						<div className="body-item">
							<h4>Costo por punto de emisión</h4>
							<span>$67,20</span>
							<strong>(anual)</strong>
							<ul>
								<li>- Firma electrónica .</li>
								<li>- Facturero electrónico con punto de emisión.</li>
								<li>- Facturero personal.</li>
								<li>
									- <strong>ILIMITADOS</strong> documentos electrónicos.
								</li>
								<li>
									- Sistema TransporTech (administración de clientes, facturas, retenciones, productos, transportistas y
									más).
								</li>
								<li>- Capacitación y soporte.</li>
							</ul>
							<a
								href="https://api.whatsapp.com/send?phone=+593978825170"
								className="btn btn-danger"
								target="noreferrer"
							>
								Contactar
							</a>
						</div>
						<div className="body-item">
							<h4>Costo por mantenimiento Operadora </h4>
							<span>$22,40</span>
							<strong>(mensual)</strong>
							<ul>
								<li>- Mantenimiento y Soporte.</li>
								<li>- Actualizaciones y Capacitación.</li>
								<li>
									- Punto de venta con comprobantes <strong>ILIMITADOS</strong>.
								</li>
								<li>- Administración y control de facturas emitidas.</li>
								<li>
									- Administración de clientes, proveedores, transportistas, productos, categorías y gestión de socios.
								</li>
								<li>- Gestión de reportes para facturas de punto de emisión y facturas a operadora.</li>
							</ul>
							<a
								href="https://api.whatsapp.com/send?phone=+593978825170"
								className="btn btn-danger"
								target="noreferrer"
							>
								Contactar
							</a>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default PriceScreen;
